<template>
  <div>
    <v-snackbar v-model="snackbar.model" timeout="5000"
      >{{ snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-form
                v-model="form1IsValid"
                ref="form1"
                @submit.prevent="updateOrganizationInfo"
              >
                <Subheader
                  icon="mdi-cog"
                  title="Stammdaten der Organisation"
                ></Subheader>
                <div class="mt-7">
                  <v-card :disabled="form1IsLoading">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="8">
                            <v-text-field
                              label="Vollständige Organisationsbezeichnung"
                              hint="Sollte keine Abkürzungen enthalten und vollständigen Namen wiederspiegeln. Beispiel: Freiwillige Feuerwehr Musterstadt"
                              required
                              :rules="[rules.required]"
                              persistent-hint
                              outlined
                              v-model.trim="organization.fullName"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              label="Organisationsnummer"
                              hint="Organisations-/Standort-/Einheitsnummer, sofern vorhanden."
                              type="number"
                              persistent-hint
                              outlined
                              v-model.number="organization.stationNumber"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Anzeigename"
                              hint="Wird für die Bezeichnung der Organisation in der Anwendung verwendet."
                              required
                              :rules="[rules.required]"
                              persistent-hint
                              outlined
                              v-model.trim="organization.displayName"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Abteilung/Behörde"
                              hint="Wird bspw. als Untertitel zusammen mit dem Anzeigenamen verwendet."
                              persistent-hint
                              outlined
                              v-model.trim="organization.department"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            color="success"
                            :loading="form1IsLoading"
                            @click="updateOrganizationInfo()"
                          >
                            <v-icon left> mdi-content-save </v-icon>
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-form
                v-model="form2IsValid"
                ref="form2"
                @submit.prevent="updateLocation"
              >
                <Subheader
                  icon="mdi-office-building-marker"
                  title="Standortinformationen"
                ></Subheader>
                <div class="mt-7">
                  <v-card :disabled="form2IsLoading">
                    <v-card-text>
                      <div class="subtitle-1">
                        Lege Standortinformationen für Deine Organisation fest,
                        die für Alarmierung, Geofencing und Automatisierungen
                        verwendet werden.
                      </div>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Straße Hausnummer"
                              hint="Beispiel: Musterstraße 123"
                              required
                              :rules="[rules.required]"
                              outlined
                              v-model.trim="organization.location.adress.street"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Adresszusatz"
                              outlined
                              v-model.trim="
                                organization.location.adress.additionalInfo
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              label="Postleitzahl"
                              hint="Beispiel: 12345"
                              required
                              :rules="[rules.required]"
                              outlined
                              v-model.trim="
                                organization.location.adress.postalCode
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              label="Stadt"
                              hint="Beispiel: Musterstadt"
                              required
                              :rules="[rules.required]"
                              outlined
                              v-model.trim="organization.location.adress.city"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-select
                              :items="['Deutschland']"
                              label="Land"
                              hint="Musterland"
                              readonly
                              required
                              :rules="[rules.required]"
                              outlined
                              v-model="organization.location.adress.country"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              label="E-Mail Adresse"
                              type="email"
                              hint="Beispiel: mail@organisation.com"
                              required
                              :rules="[rules.required, rules.email]"
                              outlined
                              prepend-inner-icon="mdi-email"
                              v-model.trim="organization.contact.email"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              label="Telefon"
                              hint="Beispiel: +49 (0)30 1234567 8"
                              type="tel"
                              required
                              :rules="[rules.required]"
                              outlined
                              prepend-inner-icon="mdi-phone"
                              v-model.trim="organization.contact.phone"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              label="Fax"
                              hint="Beispiel: +49 (0)30 1234567 9"
                              outlined
                              prepend-inner-icon="mdi-fax"
                              v-model.trim="organization.contact.fax"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model.trim="latitude"
                              outlined
                              required
                              :rules="[rules.required, rules.latitude]"
                              label="Breitengrad"
                              prepend-inner-icon="mdi-latitude"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model.trim="longitude"
                              outlined
                              required
                              :rules="[rules.required, rules.longitude]"
                              label="Längengrad"
                              prepend-inner-icon="mdi-longitude"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            color="success"
                            :loading="form2IsLoading"
                            @click="updateLocation()"
                          >
                            <v-icon left> mdi-content-save </v-icon>
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-information-variant"
                title="Informationen"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <div class="subtitle-1">
                            <strong>Status der Organisation</strong><br />
                            <div class="pb-4">
                              <v-chip label small>
                                <v-icon
                                  small
                                  left
                                  :color="
                                    getOrganizationStatusColor(
                                      organization.status
                                    )
                                  "
                                >
                                  mdi-circle
                                </v-icon>
                                {{ getOrganizationStatus(organization.status) }}
                              </v-chip>
                            </div>
                            <strong>Organisation erstellt am:</strong><br />
                            <p class="body-2">
                              {{
                                creationDateConverter(
                                  organization.meta.createdAt
                                )
                              }}
                            </p>
                            <strong>Eindeutige ID:</strong><br />
                            <p style="font-family: monospace">
                              {{ organization.meta.id }}
                            </p>
                          </div>
                        </v-col>
                        <v-divider
                          vertical
                          class="hidden-sm-and-down"
                        ></v-divider>
                        <v-col cols="12" md="6">
                          <div class="subtitle-1">
                            <strong>Anzahl an Teilnehmerkonten:</strong><br />
                            <p class="body-2">
                              {{ organization.count.totalMemberships }}
                            </p>
                            <strong>Organisationsbesitzer:</strong><br />
                            <v-chip
                              v-for="owner in organization.owners"
                              :key="owner.uid"
                              label
                              class="mr-2"
                              small
                              >{{ owner.displayName }}</v-chip
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-delete" title="Aktionen"></Subheader>
              <v-divider></v-divider>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Beim Löschen der Organisation werden alle Daten in
                      Verbindung mit dieser Organisation unwiderruflich
                      gelöscht. Die anschließende Wiederherstellung ist daher
                      grundsätzlich nicht möglich. Du kannst die Organisation
                      nur löschen, wenn diese keine Teilnehmer mehr enthält.
                    </div>
                    <v-btn color="error" depressed text class="mt-3" disabled>
                      <v-icon left> mdi-delete-alert </v-icon>
                      Organisation löschen
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { ORGANISATION } from "@/store/modules.js";
import {
  UPDATE_ORGANISATION_INFO,
  UPDATE_ORGANISATION_STANDORT,
} from "@/store/action-types.js";

export default {
  name: "admin-config-tab-general",
  components: {
    Subheader,
  },
  data() {
    return {
      snackbar: { model: false, text: "" },
      form1IsLoading: false,
      form2IsLoading: false,
      form1IsValid: false,
      form2IsValid: false,

      latitude: null,
      longitude: null,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
        latitude: (value) => {
          const pattern =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          return pattern.test(value) || "Zahl muss zwischen -90 und 90 liegen.";
        },
        longitude: (value) => {
          const pattern =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          return (
            pattern.test(value) || "Zahl muss zwischen -180 und 180 liegen."
          );
        },
      },
    };
  },
  computed: {
    organization() {
      return this.$store.state.organization.activeOrganization;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.longitude =
        this.$store.state.organization.activeOrganization.location.geopoint.longitude;
      this.latitude =
        this.$store.state.organization.activeOrganization.location.geopoint.latitude;
    },

    creationDateConverter(item) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return item.toDate().toLocaleDateString("de-DE", options);
    },
    getOrganizationStatus(status) {
      if (status === "active") {
        return "Aktiv";
      } else if (status === "inactive") {
        return "Inaktiv";
      } else {
        return "Fehler";
      }
    },
    getOrganizationStatusColor(status) {
      if (status === "active") {
        return "success";
      } else if (status === "inactive") {
        return "error";
      } else {
        return "warning";
      }
    },
    updateOrganizationInfo() {
      this.$refs.form1.validate();
      if (this.form1IsValid) {
        this.form1IsLoading = true;
        this.$store
          .dispatch(`${ORGANISATION}${UPDATE_ORGANISATION_INFO}`, {
            organizationId: this.$route.params.organizationId,
            fullName: this.organization.fullName,
            displayName: this.organization.displayName,
            department: this.organization.department,
            stationNumber: this.organization.stationNumber,
          })
          .then(() => {
            this.form1IsLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Stammdaten erfolgreich aktualisiert.";
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    updateLocation() {
      this.$refs.form2.validate();
      if (this.form2IsValid) {
        this.form2IsLoading = true;
        this.$store
          .dispatch(`${ORGANISATION}${UPDATE_ORGANISATION_STANDORT}`, {
            organizationId: this.$route.params.organizationId,
            contact: {
              email: this.organization.contact.email,
              phone: this.organization.contact.phone,
              fax: this.organization.contact.fax,
            },
            location: {
              adress: {
                street: this.organization.location.adress.street,
                additionalInfo:
                  this.organization.location.adress.additionalInfo,
                postalCode: this.organization.location.adress.postalCode,
                city: this.organization.location.adress.city,
                // country: this.organization.location.adress.country
              },
            },
            latitude: parseFloat(this.latitude),
            longitude: parseFloat(this.longitude),
          })
          .then(() => {
            this.form2IsLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Standortdaten erfolgreich aktualisiert.";
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
