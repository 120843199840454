<template>
  <div>
    <section>
      <!-- <TippCard 
        hintID="Admin_Konfiguration_Tab_Organisationsstruktur_1_V1"
        text="Eine Organisation ist grundsätzlich eine eigene und eigenständige Einheit. Um komplexe Organisationsstrukturen abzubilden, lassen sich Einstellungen und Daten vererben. Dafür kann eine Organisation einer anderen Organisation untergeordet oder übergeordnet werden. Um die optimale Organisationsstruktur und -einstellung zu finden, sind verschiedene Beispiele im Support-Portal visualisiert und erklärt."
      >
      </TippCard> -->
    </section>
    <section>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-alert
              type="warning"
              border="left"
              colored-border
              icon="mdi-alert"
              elevation="2"
              class="mt-6"
            >
              <div class="title">Hinweis</div>
              <div class="mt-2">
                Diese Organisation wird nicht durch die
                Organisationmanager-Umgebung verwaltet.
              </div>
              <div class="grey--text">
                Kontaktieren Sie den Support, um diese Funktion zu aktivieren.
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <router-view />
  </div>
</template>

<script>
// import TippCard from '@/components/_systemwide/Tipp_Card.vue'

export default {
  name: "admin-config-tab-inheritance",
  components: {
    // TippCard
  },
};
</script>
