<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Konfiguration_Tab_Erscheinungsbild_1_V1"
        text="Um die Anwendung etwas an Deine Organisation anzupassen, kannst Du das Erscheinungsbild individualisieren. So verwenden wir Dein Logo und Profilbild an den unterschiedlichsten Stelllen."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.snackbar.model" timeout="5000"
      >{{ helpers.snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-pencil-ruler"
                title="Logo und Branding"
              ></Subheader>
              <div class="mt-7">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-card>
                        <v-card-title class="title">
                          Profilbild (Avatar) der Organisation
                        </v-card-title>
                        <v-card-subtitle>
                          Zur Verwendung bspw. in der Organisationsübersicht
                        </v-card-subtitle>
                        <v-card-text>
                          <span class="font-weight-medium text-subtitle-1"
                            >Empfehlungen:</span
                          >
                          <ul>
                            <li>Maximale Dateigröße: 2 MB</li>
                            <li>
                              Datei mit hoher Auflösung: z.B. 500px x 500px
                            </li>
                            <li>
                              Bild mit quadratischem Seitenverhältnis: 1 : 1
                            </li>
                            <li>
                              Zulässige Formate:
                              <code class="mr-1">.png</code>
                              <code class="mr-1">.jpeg</code>
                            </li>
                            <li>
                              Transparente Datei oder Hintergrund mit beliebiger
                              Farbe
                            </li>
                            <li>Keine maskierten Versionen des Logos</li>
                          </ul>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          <span class="font-weight-medium text-subtitle-1"
                            >Aktuelles Profilbild</span
                          >
                          <v-card outlined class="py-4 mt-1" width="360px">
                            <organization-info-list-item></organization-info-list-item>
                          </v-card>
                        </v-card-text>
                        <v-card-text
                          v-if="avatarFile && helpers.avatarFormIsValid"
                        >
                          <span class="font-weight-medium text-subtitle-1"
                            >Vorschau</span
                          >
                          <v-card outlined class="py-4 mt-1" width="360px">
                            <organization-info-list-item
                              :alternativeAvatarUrl="avatarFilePreviewUrl"
                            ></organization-info-list-item>
                          </v-card>
                        </v-card-text>
                        <v-card-text>
                          <v-form
                            v-model="helpers.avatarFormIsValid"
                            ref="form"
                            @submit.prevent="updateAvatar"
                          >
                            <v-file-input
                              v-model="avatarFile"
                              :rules="[
                                rules.maxFileSize,
                                rules.fileType,
                                rules.required,
                              ]"
                              outlined
                              hide-details="auto"
                              chips
                              show-size
                              clearable
                              accept="image/png, image/jpeg"
                              label="Neue Datei auswählen"
                              placeholder="Profilbild (Avatar)"
                              prepend-inner-icon="mdi-image"
                              prepend-icon=""
                              type="file"
                              @click:clear="fileCleared()"
                              @change="generateAvatarFilePreviewUrl()"
                            >
                            </v-file-input>
                          </v-form>
                          <v-alert
                            v-if="helpers.error.model"
                            class="mt-6"
                            dense
                            text
                            type="error"
                          >
                            {{ helpers.error.text }}
                          </v-alert>
                        </v-card-text>

                        <v-card-actions>
                          <span
                            v-if="helpers.uploadOngoing"
                            class="ml-2 mb-2 grey--text"
                            >Datei wird hochgeladen</span
                          >
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="!helpers.uploadOngoing"
                            depressed
                            class="mr-2 mb-2"
                            color="success"
                            :disabled="!helpers.avatarFormIsValid"
                            @click="uploadNewAvatar"
                          >
                            <v-icon left> mdi-image </v-icon>
                            Neues Bild hochladen
                          </v-btn>
                          <v-progress-circular
                            v-else
                            class="ml-2 mb-2"
                            color="primary"
                            :width="6"
                            :size="45"
                            :rotate="-90"
                            indeterminate
                          ></v-progress-circular>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="6">
                          <v-card outlined>
                            <v-card-title class="title">
                              Logo (Global)
                            </v-card-title>
                            <v-card-subtitle>
                              Dieses Logo wird in Berichten und in der Anwendung
                              angezeigt, um die Anwendung zu personalisieren.
                              Wir empfehlen Dir die Verwendung eines Logos in
                              Farbe oder schwarz.
                            </v-card-subtitle>
                            <v-card-text>
                              <v-img
                                v-if="organization.config.appearance.logoUrl"
                                max-height="100"
                                contain
                                :src="organization.config.appearance.logoUrl"
                                class="grey lighten-3"
                              ></v-img>
                              <v-file-input
                                v-model="a"
                                outlined
                                hide-details="auto"
                                chips
                                show-size
                                clearable
                                accept="image/png, image/jpeg, image/jpg"
                                label="Datei auswählen"
                                placeholder="Logo"
                                prepend-icon=""
                                prepend-inner-icon="mdi-image"
                                type="file"
                                @click:clear="fileCleared()"
                              >
                              </v-file-input>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                depressed
                                class="mr-2 mb-2"
                                color="success"
                              >
                                <v-icon left> mdi-image </v-icon>
                                Speichern
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                          <v-file-input 
                            outlined
                            small-chips
                            show-size
                            clearable
                            accept="image/png, image/jpg, image/jpeg"
                            label="Logo deiner Organisation"
                            placeholder="Als .png oder .jpg hochladen"
                            hint="Empfehlung: Verwende eine transparente PNG-Datei, damit sich Dein Logo besser in die Benutzeroberfläche integrieren kann."
                            persistent-hint
                            prepend-icon="mdi-image"
                            :rules="rules"
                          >
                          </v-file-input>
                        </v-col> -->
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { storage, TaskEvent } from "@/firebase";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import OrganizationInfoListItem from "@/components/organization/OrganizationInfoListItem.vue";

export default {
  name: "admin-config-tab-appearance",
  components: {
    TippCard,
    Subheader,
    OrganizationInfoListItem,
  },
  data() {
    return {
      avatarFile: null,
      avatarFilePreviewUrl: "",
      helpers: {
        avatarFormIsValid: false,
        uploadOngoing: false,
        error: { model: false, text: "" },
        snackbar: { model: false, text: "" },
      },
      rules: {
        required: (value) => !!value || "Datei ist erforderlich.",
        maxFileSize: (value) =>
          !value ||
          value.size < 2000000 ||
          "Dateigröße muss kleiner als 2 MB sein.",
        fileType: (value) => {
          if (!value) return true; // If no file is selected, it passes validation
          const allowedTypes = ["image/jpeg", "image/png"];
          return (
            allowedTypes.includes(value.type) ||
            "Datei muss vom Typ JPEG oder PNG sein."
          );
        },
      },
    };
  },
  computed: {
    organization() {
      return this.$store.state.organization.activeOrganization;
    },
  },
  methods: {
    generateAvatarFilePreviewUrl() {
      // Generate a preview url that I can use to display the uploaded image in "src" of v-img
      this.avatarFilePreviewUrl = URL.createObjectURL(this.avatarFile);
    },
    uploadNewAvatar() {
      this.helpers.error.model = false;
      this.helpers.uploadOngoing = true;
      // Create the file metadata
      var file = this.avatarFile;
      var metadata = {
        contentType: "image/png",
      };

      var storageRef = storage.ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("organizations/")
        .child(this.$route.params.organizationId + "/")
        .child("avatar/avatar.png")
        .put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.

      uploadTask.on(
        TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // var progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case TaskEvent.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case TaskEvent.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          this.helpers.uploadOngoing = false;
          this.helpers.error.model = true;
          this.helpers.error.text = error;
          return console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          this.helpers.uploadOngoing = false;
          this.helpers.snackbar.model = true;
          this.helpers.snackbar.text = "Profilbild erfolgreich aktualisiert";
          // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          //   console.log("File available at", downloadURL);
          // });
        }
      );
    },
  },
};
</script>
