<template>
  <div>
    <NavigationBar
      titel="Systemkonfiguration"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "admin-config",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `general`,
      tabs: [
        { id: 1, name: "Allgemein", route: `general` },
        { id: 2, name: "Erscheinungsbild", route: `appearance` },
        {
          id: 3,
          name: "Organisationsstruktur",
          route: `inheritance`,
        },
        // { id: 4, name: "Einsatzmittel", route: `units` },
        // { id: 5, name: "Funktionen", route: `positions` },
      ],
    };
  },
};
</script>
